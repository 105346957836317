// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nasa-js": () => import("./../../../src/pages/nasa.js" /* webpackChunkName: "component---src-pages-nasa-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-seminars-long-index-js": () => import("./../../../src/pages/seminars/long/index.js" /* webpackChunkName: "component---src-pages-seminars-long-index-js" */),
  "component---src-pages-seminars-online-index-js": () => import("./../../../src/pages/seminars/online/index.js" /* webpackChunkName: "component---src-pages-seminars-online-index-js" */),
  "component---src-pages-seminars-short-index-js": () => import("./../../../src/pages/seminars/short/index.js" /* webpackChunkName: "component---src-pages-seminars-short-index-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

